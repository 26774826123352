import React from 'react';
import PropTypes from 'prop-types';
import { Button, notification, Tooltip } from 'antd';
import { useMutation, useReactiveVar } from '@apollo/client';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import css from './add-to-favorites-button.module.less';
import { WISHLIST_ADD_VARIANTS } from '../../../graphql/mutations/wishlistAddVariants';
import { WISHLIST_REMOVE_VARIANTS } from '../../../graphql/mutations/wishlistRemoveVariants';
import { actWishId, actWishVariants } from '../../../apollo/cache';
import { inMemoryAuth } from '../../auth/in-memory-auth';

export const AddToFavoritesButton = ({
  variantId,
  withText,
}) => {
  const wishlistId = useReactiveVar(actWishId);
  const wishlistVariants = useReactiveVar(actWishVariants);

  const [wishlistAddVariants, { loadingAdd }] = useMutation(WISHLIST_ADD_VARIANTS, {
    variables: {
      id: wishlistId,
      variantIds: [variantId],
    },
    onCompleted: (data) => {
      if (data.wishlistAddVariants.wishlistErrors.length === 0) {
        const variantsIdList = data.wishlistAddVariants
          .wishlist.productVariants?.map((variant) => variant.id);
        actWishVariants(variantsIdList);
      }
    },
  });

  const [wishlistRemoveVariants, { loadingRemove }] = useMutation(WISHLIST_REMOVE_VARIANTS, {
    variables: {
      id: wishlistId,
      variantIds: [variantId],
    },
    onCompleted: (data) => {
      if (data.wishlistRemoveVariants.wishlistErrors.length === 0) {
        const variantsIdList = data.wishlistRemoveVariants
          .wishlist.productVariants?.map((variant) => variant.id);
        actWishVariants(variantsIdList);
      }
    },
  });

  const isInWishlist = wishlistVariants?.includes(variantId);

  const toggleFavourites = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!wishlistId) {
      const existing = JSON.parse(localStorage.getItem('actWishVariants')) || [];
      if (existing.length === 1) {
        notification.info({
          message: 'Tip: Contact Suppliers',
          description: 'Navigate to the Favourites page to contact suppliers',
        });
      }
      if (existing.length === 5) {
        notification.info({
          message: 'Tip: Create an account',
          description: 'Create an account to make sure your favourites are saved in'
            + ' the cloud.',
        });
      }
      if (inMemoryAuth.token && existing.length === 0) {
        notification.warning({
          message: 'No List Active',
          description: 'You are saving to temporary storage in the browser. You might lose'
            + ' information this way. Please,'
            + ' create & activate a list using your Account page',
        });
      }
      let updated;
      if (!isInWishlist) {
        updated = [...existing, variantId];
      } else {
        updated = existing.filter((val) => val !== variantId);
      }
      actWishVariants(updated);
      localStorage.setItem('actWishVariants', JSON.stringify(updated));
      return null;
    }
    if (isInWishlist) {
      return wishlistRemoveVariants();
    }
    return wishlistAddVariants();
  };

  return (
    <Tooltip title={`${isInWishlist ? 'Remove from' : 'Save to'} favourites`}>
      <Button
        className={css.favourite}
        danger
        size="large"
        loading={loadingAdd || loadingRemove}
        onClick={toggleFavourites}
        icon={isInWishlist ? <AiFillHeart /> : <AiOutlineHeart />}
      >
        {withText ? 'Add to Favourites' : null}
      </Button>
    </Tooltip>
  );
};

AddToFavoritesButton.defaultProps = {
  withText: false,
};

AddToFavoritesButton.propTypes = {
  variantId: PropTypes.string.isRequired,
  withText: PropTypes.bool,
};
