import { gql } from '@apollo/client';

export const WISHLIST_REMOVE_VARIANTS = gql`
    mutation wishlistRemoveVariants($id: ID!, $variantIds: [ID]! ) {
        wishlistRemoveVariants(id: $id, input: {variantIds: $variantIds}) {
            wishlist {
                productVariants {
                    id
                }
            }
            wishlistErrors {
                code
                field
                message
            }
        }
    }
`;
